<template lang="pug">
q-page(style="margin-top:-35px;")
  .bg-primary.text-white.hero1.row(ref="toolbar")
    .lt-md.hero2a
    .col
      .row
        .col-12
          Breadcrumbs(:title="$t('theory')")
      .row.items-center.full-height
        .col-9.col-md-12.q-pb-xl
          div().q-pb-xl.q-ma-dynamic
            h1 {{$t('learn-from-innovation-experts-and-peer-mentors')}}
            .text-body1 {{ $t('learn-the-essential-skills-and-get-the-resources-to-bring-your-climate-innovations-to-life') }}
    .col-6.gt-sm
      .hero2
  
  //- div(style="height:50px;" v-show="snap")  

  //- .row
  //-   .col {{currentlang}}
  //-     q-select(:options="languages" v-model="currentlang" emit-value map-options)

  .bg-white
    q-tabs(v-model="phase" no-caps class="text-primary" align="justify")
      q-tab(name="phase_1" :label="$t('phases.phase_2.title')")
      q-tab(name="phase_2" :label="$t('phases.phase_3.title')")

    q-tab-panels(v-model="phase"  color="primary" header-nav alternative-labels)
      q-tab-panel(
        name="phase_1"
        prefix="1"
        icon="none"
        done-icon="1"
        active-icon="1"
        
        )
        .bg-white(id="about")
          .row.items-center.full-height
            .col-md-12.col-12.q-pb-xl
              .q-ma-dynamic
                .row.full-width
                  .col-12.col-sm-3
                    q-list
                      q-item
                        q-select.full-width(:options="languages" v-model="currentlang" emit-value map-options dense)
                      q-item(no-caps v-for="(topic,index) of training_p1" clickable selected :active="index==step" @click="step = index" :key="index") {{getTitle(topic)}}

                  .col-12.col-sm
                    q-tab-panels(v-model="step")
                      q-tab-panel(:name="index" v-for="(topic,index) of training_p1" :key="index")
                        .text-h2.q-mb-md {{getTitle(topic)}}
                        .row.q-col-gutter-sm.q-mt-lg
                          .col-12.col-sm-6
                            //- .text-primary.text-bold Video
                            q-video(:src="getVideoUrl(topic)" :ratio="9/16")
                          .col-12.col-sm-6(v-if="topic.onepage !== false")
                            q-list(separator)
                              q-item(clickable :href="getOnePagerUrl(topic)" target="_BLANK")
                                q-item-section {{ $t('summary-and-reference-document') }}
                                q-item-section(side)
                                  q-icon(:name="matNorthEast") 
                                //- q-item-section(side)
                                //-   q-btn.on-left(no-caps color="primary" :icon-right="matNorthEast" :href="getOnePagerUrl(topic)" target="_BLANK")
                              
                              q-item(v-for="index in 5" :key="index" v-if="topic.methods" clickable :href="getMethodUrl(topic,index)"  target="_BLANK")
                                q-item-section {{getMethodTitle(topic,index)}}
                                q-item-section(side)
                                  q-icon(:name="matNorthEast") 
      q-tab-panel(
        name="phase_2"
        prefix="2"
        icon="none"
        done-icon="2"
        active-icon="2"
        :title="$t('phases.phase_3.title')"
        )
        .bg-white(id="about")
          .row.items-center.full-height
            .col-md-12.col-12.q-pb-xl
              .q-ma-dynamic
                .row.full-width
                  .col-12.col-sm-3
                    q-list
                      q-item
                        q-select.full-width(:options="languages" v-model="currentlang" emit-value map-options dense)
                      q-item(no-caps v-for="(topic,index) of training_p2" clickable selected :active="index==step" @click="step = index" :key="index") {{getTitle(topic)}}

                  .col-12.col-sm
                    q-tab-panels(v-model="step")
                      q-tab-panel(:name="index" v-for="(topic,index) of training_p2" :key="index")
                        .text-h2.q-mb-md {{getTitle(topic)}}
                        .row.q-col-gutter-sm.q-mt-lg
                          .col-12.col-sm-6
                            .row.justify-center.q-col-gutter-sm.items-stretch
                              .col-6(v-for="video in topic.videos")
                                
                            
                                q-video(:src="getVideoUrl(video,'phase_2')" :ratio="9/16")
                                .text-primary.text-bold {{ $t(`${video.title}_title`) }}
                          .col-12.col-sm-6(v-if="topic.onepage !== false")
                            q-list(separator)
                              q-item(clickable :href="getOnePagerUrl(topic,'phase_2')" target="_BLANK")
                                q-item-section {{ $t('summary-and-reference-document') }}
                                q-item-section(side)
                                  q-icon(:name="matNorthEast") 
                                //- q-item-section(side)
                                //-   q-btn.on-left(no-caps color="primary" :icon-right="matNorthEast" :href="getOnePagerUrl(topic)" target="_BLANK")
                              
                              q-item(v-for="(m,index) of topic.onepagers" :key="index" v-if="topic.methods" clickable :href="getMethodUrl(topic,index+1,'phase_2')"  target="_BLANK")
                                q-item-section {{`${$t(topic.method_prefix)} ${index+1}`}}
                                q-item-section(side)
                                  q-icon(:name="matNorthEast") 
                      

</template>

<script>
  import Breadcrumbs from "../components/Breadcrumbs.vue";
  import { DateTime } from "luxon";
  import { map } from "lodash";
  import { mapState, mapActions } from "vuex";
  import Language from "@/mixins/Language";
  import { loadLanguageAsync } from "../i18n";
  let lang_rtl = {
    ur: "ar",
    fa: "ar",
    pa: "ar",
    ar: "ar",
  };

  export default {
    mixins: [Language],
    components: { Breadcrumbs },
    data: function() {
      return {
        langs: {
          phase_1: [
            "en",
            "es",
            "fr",
            "ar",
            "pl",
            "id",
            "zh",
            "ru",
            "bn",
            "pt",
            "ja",
            "it",
          ],
          phase_2: [
            "en",
            "es",
            "fr",
            "ar",
            "ja",
            "pt",
            "id",
            "bn",
            "it",
            "zh",
            "pl",
            "ko",
            "ru",
          ],
        },
        phase: "phase_2",
        currentlang: "en",
        snap: false,
        step: "t05",
        training_p2: {
          t05: {
            prefix: "p2_climate_1",
            videos: [
              {
                title: "p2_climate_1",
                video: "p2_climate1.mp4",
              },
              {
                title: "p2_climate_2",
                video: "p2_climate2.mp4",
              },
              {
                title: "p2_climate_3",
                video: "p2_climate3.mp4",
              },
            ],
            onepage: "climate.pdf",
          },
          t2: {
            prefix: "p2_prototyping_1",
            videos: [
              {
                title: "p2_prototyping_1",
                video: "p2_prototyping1.mp4",
              },
              {
                title: "p2_prototyping_2",
                video: "p2_prototyping2.mp4",
              },
              {
                title: "p2_prototyping_3",
                video: "p2_prototyping3.mp4",
              },
            ],
            onepage: "prototyping.pdf",
            methods: true,
            method_prefix: "p2_prototyping_3_title",
            onepagers: [
              "determine_what_to_prototype.pdf",
              "rapid_prototyping.pdf",
              "storyboard.pdf",
              "role_playing.pdf",
            ],
          },
          t3: {
            prefix: "p2_testing_1",
            videos: [
              {
                title: "p2_testing_1",
                video: "p2_testing1.mp4",
              },
              {
                title: "p2_testing_2",
                video: "p2_testing2.mp4",
              },
              {
                title: "p2_testing_3",
                video: "p2_testing3.mp4",
              },
            ],
            onepage: "testing.pdf",
            methods: true,
            method_prefix: "p2_testing_2_title",
            onepagers: [
              "get_feedback.pdf",
              "keep_getting_feedback.pdf",
              "monitor_and_evaluate.pdf",
              "download_your_learnings.pdf",
            ],
          },
          t4: {
            prefix: "p2_iteration_1",
            videos: [
              {
                title: "p2_iteration_1",
                video: "p2_iteration1.mp4",
              },
              {
                title: "p2_iteration_2",
                video: "p2_iteration2.mp4",
              },
              {
                title: "p2_iteration_3",
                video: "p2_iteration3.mp4",
              },
            ],
            onepage: "iteration.pdf",
            methods: true,
            method_prefix: "p2_iteration_3_title",
            onepagers: [
              "integrate_and_iterate.pdf",
              "keep_iterating.pdf",
              "feedback_matrix.pdf",
              "hypothesis_matrix.pdf",
            ],
          },
          t5: {
            prefix: "p2_leadership_1",
            videos: [
              {
                title: "p2_leadership_1",
                video: "p2_leadership1.mp4",
              },
              {
                title: "p2_leadership_2",
                video: "p2_leadership2.mp4",
              },
              {
                title: "p2_leadership_3",
                video: "p2_leadership3.mp4",
              },
            ],
            onepage: "leadership.pdf",
          },
        },

        training_p1: {
          t05: {
            prefix: "p1_intro",
            video: "p1_intro.mp4",
            onepage: false,
          },
          t0: {
            prefix: "p1_howitworks",
            video: "p1_howitworks.mp4",
            onepage: false,
          },
          t1: {
            prefix: "p1_climate",
            video: "p1_climate.mp4",
            onepage: "climate.pdf",
          },
          t2: {
            prefix: "p1_empathise_intro",
            video: "p1_empathise.mp4",
            onepage: "empathise.pdf",
            methods: true,
            method_prefix: "p1_empathise_method_",
            onepagers: [
              "interview.pdf",
              "group_interview.pdf",
              "secondary_research.pdf",
              "conversation_starters.pdf",
              "immersion.pdf",
            ],
          },
          t3: {
            prefix: "p1_define_intro",
            video: "p1_define.mp4",
            onepage: "define.pdf",
            methods: true,
            method_prefix: "p1_define_method_",
            onepagers: [
              "download_your_learnings.pdf",
              "top_5.pdf",
              "find_themes.pdf",
              "insight_statements.pdf",
              "how_might_we.pdf",
            ],
          },
          t4: {
            prefix: "p1_ideate_intro",
            video: "p1_ideate.mp4",
            onepage: "ideate.pdf",
            methods: true,
            method_prefix: "p1_ideate_method_",
            onepagers: [
              "brainstorm.pdf",
              "create_frameworks.pdf",
              "bundle_ideas.pdf",
              "get_visual.pdf",
              "mash_ups.pdf",
            ],
          },
          t5: {
            prefix: "p1_refine_intro",
            video: "p1_refine.mp4",
            onepage: "refine.pdf",
            methods: true,
            method_prefix: "p1_refine_method_",
            onepagers: [
              "design_principles.pdf",
              "create_a_concept.pdf",
              "co-creation_session.pdf",
              "gut_check.pdf",
              "storyboard.pdf",
            ],
          },
          t6: {
            prefix: "p1_leadership",
            video: "p1_leadership.mp4",
            onepage: "leadership.pdf",
          },
        },
      };
    },
    computed: {
      ...mapState(["config"]),
      messages() {
        return this.$i18n.messages[this.$i18n.locale];
      },
      languages() {
        return map(this.langs[this.phase], (l) => {
          // console.log(this.codeToLang("en"));
          return {
            label: this.codeToLang(l),
            value: l,
          };
        });
      },
    },
    async mounted() {
      this.currentlang = this.$i18n.locale;
      await Promise.all([this.initConfig()]);
    },
    watch: {
      "$i18n.locale"() {
        console.log(this.$i18n.locale);
        this.currentlang = this.$i18n.locale;
      },
      async currentlang() {
        await loadLanguageAsync(this.currentlang);

        if (lang_rtl[this.currentlang]) {
          let lang = await import("quasar/lang/ar");
          this.$q.lang.set(lang.default);
        } else {
          let lang = await import("quasar/lang/en-us");
          this.$q.lang.set(lang.default);
        }

        // this.$i18n.locale = this.currentlang;
      },
    },
    methods: {
      ...mapActions(["initConfig"]),
      getMethodTitle(topic, index) {
        return this.$t(`${topic.method_prefix}${index}`);
      },
      getTitle(key) {
        return this.$t(`${key.prefix}_title`);
      },
      getMethodUrl(topic, index, phase = "phase_1") {
        if (!this.config) return "";
        let lang = "en";
        if (
          [
            "en",
            "es",
            "fr",
            "ar",
            "pl",
            "id",
            "zh",
            "ru",
            "bn",
            "pt",
            "ja",
            "it",
          ].includes(this.$i18n.locale)
        )
          lang = this.$i18n.locale;

        // console.log(topic.onepgares[index - 1]);

        return `${
          this.config.cloudfronturl
        }/assets/${phase}/onepagers/${lang}/${topic.onepagers[index - 1]}`;
      },
      getOnePagerUrl(index, phase = "phase_1") {
        if (!this.config) return "";
        let lang = "en";
        if (
          [
            "en",
            "es",
            "fr",
            "ar",
            "pl",
            "id",
            "zh",
            "ru",
            "bn",
            "pt",
            "ja",
            "it",
          ].includes(this.$i18n.locale)
        )
          lang = this.$i18n.locale;
        return `${this.config.cloudfronturl}/assets/${phase}/onepagers/${lang}/${index.onepage}`;
      },
      getVideoUrl(topic, phase = "phase_1") {
        // const video = `${topic.prefix}.mp4`;

        if (!this.config) return "";
        // console.log(this.config);
        // return video;
        let lang = "en";
        if (
          [
            "en",
            "es",
            "fr",
            "ar",
            "pl",
            "id",
            "zh",
            "ru",
            "bn",
            "pt",
            "ja",
            "it",
          ].includes(this.$i18n.locale)
        )
          lang = this.$i18n.locale;
        return `${this.config.cloudfronturl}/assets/${phase}/videos/${lang}/${topic.video}`;
      },
      localiseDateExact(str) {
        let dt = DateTime.fromFormat(str, "dd/MM/yyyy");
        return dt.toLocaleString({ ...DateTime.DATE_FULL });
      },
      localiseDate(str) {
        let dt = DateTime.fromFormat(str, "dd/MM/yyyy");
        return dt.toLocaleString({ month: "long", year: "numeric" });
      },
      localiseDateRange(str1, str2) {
        let dt1 = DateTime.fromFormat(str1, "dd/MM/yyyy");
        let dt2 = DateTime.fromFormat(str2, "dd/MM/yyyy");

        return `${dt1.toLocaleString({
          month: "long",
        })} - ${dt2.toLocaleString({ month: "long", year: "numeric" })}`;
      },
    },
  };
</script>

<style lang="scss">
  @import "@/styles/quasar.variables.scss";
  // .q-stepper__dot {
  //   // width: 8px !important;
  //   min-width: inherit !important;
  //   width: 16px !important;
  //   height: 16px !important;
  //   color: black;
  //   border-radius: 0 !important;
  // }

  // .q-stepper__dot:after,
  // .q-stepper__dot:before {
  //   background-color: $accent !important;
  //   width: 4px !important;
  //   transform: translateX(-2px);
  // }

  // .q-stepper__title {
  //   font-size: 1.4rem !important;
  //   line-height: 1.7rem !important;
  // }

  // .q-stepper__caption {
  //   color: $secondary;
  //   font-size: 1rem !important;
  //   line-height: 1.6rem !important;
  // }

  .q-tabs--vertical .q-tab {
    // color: red;
    text-align: left !important;
    display: block !important;
    white-space: inherit !important;
  }

  .q-tab__label {
    text-align: left !important;
  }
</style>

<style lang="scss" scoped>
  @import "@/styles/quasar.variables.scss";

  .hero1 {
    min-height: 700px;
    position: relative;
  }

  .hero2 {
    height: 100%;
    // background: rgb(21, 0, 36);
    background-image: url("/img/academy1.webp"),
      linear-gradient(285deg, $accent 63%, $primary 63.2%);
    // background-image: linear-gradient(285deg, #d9bad7 63%, $secondary 63.2%);
    background-repeat: no-repeat;
    background-position: bottom -40px right 40px, center;
    // background-position-y: bottom, center;
    // background-position-x: right;
    background-size: 80%, 100%;
  }

  .hero4 {
    background-image: url("/img/hero4.webp");
    height: 100%;
    min-height: 50vw;
    background-repeat: no-repeat;
    background-size: 120%;
    background-position: bottom -10px right;
    // background-position-x: left;
  }

  .hero3 {
    background-image: url("/img/academy1.webp");
    height: 100%;
    min-height: 40vw;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom -10px left -30px;
    // background-position-x: left;
  }

  .hero3a {
    background-image: url("/img/academy1.webp");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom -10px left -30px;
    opacity: 0.1;
    position: absolute;
    background-size: 100vw;
    bottom: 0;
    left: 0;
  }

  .hero-border {
    // border-bottom: $lilac 60px solid;
    background-color: $lilac;
    // background: url("~@/assets/img/lilacborder.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    position: absolute;
    bottom: -30px;
    height: 60px;
    // left: -1px;
    width: 100%;
    transform: skew(0, -0.5deg);
    overflow: hidden;
  }

  .hero2a {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    // background-color: red;
    background-image: url("/img/academy1.webp");
    opacity: 0.1;
    background-repeat: no-repeat;
    // background-position: right top;
    background-position: right -150px bottom -40px !important;
    // background-position-x: right30%;
  }

  .fixme {
    top: 70px;
    position: fixed;
    z-index: 1000;
    border-bottom: 1px solid white;
  }
</style>
